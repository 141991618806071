/* eslint-disable no-console */
import Vue from 'vue'
import App from './App.vue'
import appConfig from "@/app.config"
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
/* import VueMask from 'v-mask' */
import * as VueGoogleMaps from 'vue2-google-maps'
/* import VueSweetalert2 from 'vue-sweetalert2' */
import VueApexCharts from 'vue-apexcharts'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"
import "@/design/index.scss"
import Sparkline from 'vue-sparklines'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import InstantSearch from 'vue-instantsearch'
// import SmartBanner from 'smart-app-banner'
// import 'smart-app-banner/dist/smart-app-banner.css'
// import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import VueFormWizard from 'vue-form-wizard'
import GSignInButton from 'vue-google-signin-button'
import VueAppleLogin from 'vue-apple-login'
import VueToastr from '@deveodk/vue-toastr'
import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css'
import VueResource from 'vue-resource'
import VueI18n from 'vue-i18n'
import { languages } from './languages/index.js'
import { defaultLocale } from './languages/index.js'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/da'
import VueCookies from 'vue-cookies'
import loading  from 'vue-full-loading'
import VueGoogleCharts from 'vue-google-charts'
import { v4 as uuidv4 } from 'uuid'
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import VueYoutube from 'vue-youtube'
import device from 'vue-device-detector-js'
import moment from 'moment'
import * as Sentry from "@sentry/vue"

Vue.config.devtools = process.env.NODE_ENV != 'production' || window.location.href.includes('devMode') ? true : false

// momentjs
Vue.prototype.moment = moment
moment.locale('da')
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).locale('da').format('Do MMM YYYY')
  }
});
Vue.filter('kroner', function(value) {
  if (typeof value !== "number") {
    return value
  }
  var formatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK'
  })
  return formatter.format(value)
});
Vue.use(InstantSearch)
Vue.use(wysiwyg, {
    hideModules: {
      'justifyRight': true,
      'justifyCenter': true,
      'code': true,
      'table': true,
      'italic': true,
      'removeFormat': true
    },
    'forcePlainTextOnPaste': true
  })
  // vue js-dialog
// vue form wizard
Vue.use(VueFormWizard)
  // vue google sign in button
Vue.use(GSignInButton)
var currentTime = new Date()
  // vue apple login
Vue.use(VueAppleLogin, {
  clientId: 'dk.haandvaerker.minbolig.webapp',
  scope: 'email name',
  redirectURI: encodeURI('https://' + process.env.VUE_APP_HOST + '/login'),
  state: currentTime.toString(),
  usePopup: true,
});
// vue toastr
Vue.use(VueToastr, {
    defaultPosition: 'toast-top-right',
    defaultType: 'info',
    defaultTimeout: 2000
  })
  // vue resource
Vue.use(VueResource)
require('dotenv').config()
Vue.http.options.root = process.env.VUE_APP_BITRIX_API + '/v1'
Vue.http.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  // vue-i18n
Vue.use(VueI18n)
const messages = Object.assign(languages)
var i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'dk',
    messages
  })
  // element ui
Vue.use(ElementUI, { locale })

// vue-cookies
Vue.use(VueCookies)
Vue.use(loading)
// main website list
Vue.prototype.$mainwebsite = process.env.VUE_APP_MAIN_WEBSITE
Vue.prototype.$bitrixapi = process.env.VUE_APP_BITRIX_API
Vue.prototype.$hostwebsite = process.env.VUE_APP_HOST
// vue-simple-context-menu
/* import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'
import VueSimpleContextMenu from 'vue-simple-context-menu'
Vue.use(VueSimpleContextMenu)
Vue.component('vue-simple-context-menu', VueSimpleContextMenu) */
Vue.use(VueGoogleCharts)
// uuidv4
// import uuidv4 from 'uuid/v4'
Vue.use(uuidv4)
Vue.use(BootstrapVue)
Vue.use(VueSidebarMenu)
Vue.config.productionTip = false
Vue.use(Vuelidate);
Vue.use(VueGoodTablePlugin);
Vue.use(VueYoutube)
Vue.use(device)
/* Vue.use(VueMask) */
/* Vue.use(VueSweetalert2) */
/* Vue.use(require('vue-chartist')) */
Vue.use(vco)
Vue.use(Sparkline)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyACKEEIJEnvzfLgrNqqEsyMvorGMkQa7cA',
    libraries: 'places',
  },
  installComponents: true
})
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
// import ganttastic from '@infectoone/vue-ganttastic'
// Vue.use(ganttastic)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')


//Sentry
if (process.env.VUE_APP_BITRIX_API === "https://bitrixapi.haandvaerker.dk" || process.env.VUE_APP_BITRIX_API === "https://bitrixapimaster.haandvaerker.dk") {
  Sentry.init({
    Vue,
    environment: "production",
    dsn: process.env.VUE_APP_BITRIX_API === "https://bitrixapi.haandvaerker.dk" ? "https://19e8afb9fec543d3add112239ff71424@o177088.ingest.sentry.io/4504377548603392" : 'https://961f812c225d07133bba9539c944a156@o4506545050615808.ingest.sentry.io/4506545051795456',
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

Vue.prototype.$getBrowser = function () {
  const userAgent = navigator.userAgent
  const isIOS = userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1
  const isAndroid = userAgent.indexOf('Android') !== -1
  if (userAgent.includes('FxiOS')) {
    // alert('Firefox for iOS')
    return {
      engine: 'FxiOS',
      platform: isIOS ? 'iOS' : 'Other'
    }
  }
  if (userAgent.includes('CriOS')) {
    // alert('Chrome for iOS')
    return {
      engine: 'CriOS',
      platform: isIOS ? 'iOS' : 'Other'
    }
  }
  if (userAgent.includes('Safari/') && !userAgent.includes('CriOS')) {
    // alert('Safari for iOS')
    return {
      engine: 'Safari',
      platform: isIOS ? 'iOS' : 'Other'
    }
  }
  // check for android
  if (isAndroid) {
    return {
      engine: 'Android',
      platform: 'Android'
    }
  }
  // If not android or iOS
  return {
    engine: 'Unknown',
    platform: 'Unknown'
  }
}

// Global notifications. Ex: email verification
Vue.prototype.$globalNotificationData = []
Vue.prototype.$globalNotification = (data, titleEn, titleDa, type, buttonTextEn, buttonTextDa) => {
  if (Vue.prototype.$globalNotificationData.filter((property) => { 
    return property.titleEn == titleEn && property.titleDa == titleDa && property.type == type}).length === 0) {
    Vue.prototype.$globalNotificationData.push({
      data: data,
      titleEn: titleEn,
      titleDa: titleDa,
      type: type,
      buttonTextEn: buttonTextEn,
      buttonTextDa: buttonTextDa,
    })
  }
  Vue.prototype.$globalNotificationShow = true
}


// Global dynamic translation
Vue.prototype.$dynamicTranslate = (en, dk) => {
  return i18n.locale == 'dk' ? dk : en
}

// Global format currency
Vue.prototype.$formatKR = (amount, hasCurrency) => {
  var formattedAmount = amount && !isNaN(amount) ? parseFloat(amount) : 0

  if (i18n.locale == 'dk') {
    // formattedAmount = new Intl.NumberFormat('da-DK').format(formattedAmount)
    formattedAmount = (parseFloat(formattedAmount)).toLocaleString('da', { style: 'currency', currency: 'DKK' })
  } else if (i18n.locale == 'en') {
    // formattedAmount = new Intl.NumberFormat('en-GB').format(formattedAmount)
    formattedAmount = (parseFloat(formattedAmount)).toLocaleString('da', { style: 'currency', currency: 'DKK' })
  }
  
  return hasCurrency ? formattedAmount : formattedAmount.toString().replace(',00', '').replace('kr.', '')

}
// format millions
Vue.prototype.$millions = (amount, hasCurrency) => {
  var formattedAmount = amount && !isNaN(amount) ? parseFloat(amount) / 1000000 : 0

  if (i18n.locale == 'dk') {
    formattedAmount = formattedAmount.toLocaleString('da', { 
      minimumFractionDigits: 1, 
      maximumFractionDigits: 3 
    });
  } else if (i18n.locale == 'en') {
    formattedAmount = formattedAmount.toLocaleString('en', { 
      minimumFractionDigits: 1, 
      maximumFractionDigits: 3 
    });
  }

  return hasCurrency ? `${formattedAmount} mio. kr` : formattedAmount;
}



// Global validate file limit
Vue.prototype.$isValidFileSize = (file) => {
  var fileSize = file.size / 1024 / 1024;
  var fileLimitMB = 1000
  var valid = false
  if (fileSize <= fileLimitMB) {
    valid = true
  }
  return valid
}

// Global validate file type
Vue.prototype.$isValidFileType = (file, allowedFileTypes) => {
  var valid = false
  var fileType = file.name.split('.')[file.name.split('.').length - 1].toLowerCase()
  if (allowedFileTypes.includes(fileType)) {
    valid = true
  }
  return valid
}

// Global calculate the difference of two amounts
Vue.prototype.$calculateTwoAmountsDifference = (baseAmount, amount) => {
  var difference = null
  var percentageDifference = null
  var isGreaterBaseAmount = false

  difference = baseAmount - amount
  percentageDifference = 100 - (amount / baseAmount * 100).toFixed(1)
  
  if (baseAmount > amount) {
    isGreaterBaseAmount = true
  }

  var differenceInfo = {
    difference: difference,
    percentageDifference: percentageDifference,
    isGreaterBaseAmount: isGreaterBaseAmount
  }
  
  return differenceInfo
}

// Global mask input number to xxxxxx-xxxxx
Vue.prototype.$maskNumber = (value, type) => {
  var val = ''
  var stringValue = ''
  var formattedValue = ''
  if (value && type) {
    val = value.toString().replaceAll('-', '')
    stringValue = val
    if (type === 'cpr') {
      if (stringValue.length >= 6) {
        var valueCharData = Array.from(stringValue)
        var firstTexts = valueCharData[0] + valueCharData[1] + valueCharData[2] + valueCharData[3] + valueCharData[4] + valueCharData[5]
        var secondTexts = ''
        valueCharData.forEach((prop, index) => {
          if (index > 5) {
            secondTexts += prop
          }
        })
        formattedValue = firstTexts + '-' + secondTexts
      } else {
        formattedValue = value
      }
    }
  } else {
    formattedValue = value
  }
  return formattedValue
}

// Global scroll to element id
Vue.prototype.$scrollTo = (elementId) => {
  var element = document.getElementById(elementId)
  element.scrollIntoView({
    behavior: 'smooth'
  })
}

// Global smart banner
// Vue.prototype.$loadSmartBanner = () => {
//   document.querySelector('.googlePlay').setAttribute('content', 'app-id=' + process.env.VUE_APP_MOBILE_GOOGLE_PLAY_ID)
//   document.querySelector('.appleItunes').setAttribute('content', 'app-id=' + process.env.VUE_APP_MOBILE_APP_STORE_ID)
  
//   new SmartBanner({
//     daysHidden: -1,   // days to hide banner after close button is clicked (defaults to 15)
//     daysReminder: -1, // days to hide banner after "VIEW" button is clicked (defaults to 90)
//     title: 'Minbolig APP',
//     author: 'Håndværker.dk',
//     button: 'Downloade',
//     store: {
//       ios: 'På App Store',
//       android: 'I Google Play',
//       // windows: 'In Windows store'
//     },
//     price: {
//       ios: 'GRATIS',
//       android: 'GRATIS',
//       // windows: 'GRATIS'
//     },
//     theme: 'android' // put platform type ('ios', 'android', etc.) here to force single theme on all device
//     // , icon: '' // full path to icon image if not using website icon image
//   })
//   var smartBanner = document.querySelector('.smartbanner-show')
//   if (smartBanner) {
//     smartBanner.style.margin = '0'
//     smartBanner.style.fontFamily = 'Noto Sans, sans-serif'
//   }
// }

// Global truncate text
Vue.prototype.$truncateTexts = (texts, charLimit) => {
  var truncatedTexts = ''
  if (texts && texts.length >= charLimit) {
    truncatedTexts = texts.substring(0, charLimit) + '...'
  } else {
    truncatedTexts = texts
  }
  return truncatedTexts
}

// Global translate browser tab title
Vue.prototype.$translateBrowserTabTitle = () => {
  setTimeout(() => {
    var headerTitle = document.querySelector('.profile-header #headerTitle')
    if (headerTitle) {
      document.title = headerTitle.innerText + ' | ' + appConfig.description
    }
  }, 500)
}

// Global get previous page info
Vue.prototype.$savePreviousPageInfo = (previousRoute) => {
  setTimeout(() => {
    if (previousRoute && previousRoute.name) {
      var savedPreviousRoute = localStorage.getItem('previousRoute') ? JSON.parse(localStorage.getItem('previousRoute')) : ''
      var currentRouteNotSameInPreviousRoute = false

      if (savedPreviousRoute && savedPreviousRoute.name) {
        if (previousRoute.name != savedPreviousRoute.name && previousRoute.path != window.location.pathname) {
          currentRouteNotSameInPreviousRoute = true
        }
      } else {
        currentRouteNotSameInPreviousRoute = true
      }

      if (currentRouteNotSameInPreviousRoute) {
        var prevRouteInfo = {
          name: previousRoute.name,
          path: previousRoute.path,
          fullPath: previousRoute.fullPath
        }
        localStorage.setItem('previousRoute', JSON.stringify(prevRouteInfo))
      }
    }
  }, 500)
}

// Global prechange sidebar profile image
Vue.prototype.$changeSideBarProfilePicture = (imageSrc, type) => {
  var sidebarProfileImage = document.querySelector('#profileInfo .profileImage img')

  if (sidebarProfileImage) {
    localStorage.setItem('profile_picture', imageSrc)
    if (type == 'update') {
      sidebarProfileImage.src = imageSrc
    }
  }
}

// Global copy text
Vue.prototype.$copyText = (coppiedTexts) => {
  navigator.clipboard.writeText(coppiedTexts)
}

// Global disable all actions on pages
Vue.prototype.$disableAllPageActions = () => {
  setTimeout(() => {
    document.querySelector('#layout-wrapper').classList.add('events-none')
  }, 500)
}

// Global enable all actions on pages
Vue.prototype.$enableAllPageActions = () => {
  setTimeout(() => {
    document.querySelector('#layout-wrapper').classList.remove('events-none')
  }, 500)
}

// Global getScreenSize
const GlobalState = new Vue({
  data: {
    windowWidth: window.innerWidth
  }
})
export default GlobalState
window.addEventListener('resize', () => {
  GlobalState.windowWidth = window.innerWidth
})
Vue.mixin({
  computed: {
    isMobile() {
      return GlobalState.windowWidth >= 320 && GlobalState.windowWidth <= 768
    },
    isMiniTablet() {
      return GlobalState.windowWidth >= 769 && GlobalState.windowWidth <= 800
    },
    isTablet() {
      return GlobalState.windowWidth >= 801 && GlobalState.windowWidth <= 960
    },
    isLaptop() {
      return GlobalState.windowWidth >= 961 && GlobalState.windowWidth <= 1280
    },
    isDesktop() {
      return GlobalState.windowWidth >= 1281 && GlobalState.windowWidth <= 1440
    },
    isDesktopXL() {
      return GlobalState.windowWidth >= 1441
    }
  },
  methods: {
    getCurrentScreenSize() {
      if (this.isMobile) return 'Mobile'
      if (this.isTablet) return 'Tablet'
      if (this.isLaptop) return 'Laptop'
      if (this.isDesktop) return 'Desktop'
      if (this.isDesktopXL) return 'Desktop XL'
      return 'Unknown Size'
    }
  }
})
